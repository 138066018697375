var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sector"},[_c('SanackBar',{attrs:{"snackActivated":_vm.snackActivated,"snackMessage":_vm.snackMessage},on:{"closeSnackBar":function($event){return _vm.closeSnack()}}}),_c('div',{staticClass:"sector-head"},[_c('h1',{staticClass:"sector-head-title"},[_vm._v(_vm._s(_vm.titles.sector))])]),_c('div',{staticClass:"search"},[_c('div',{staticClass:"field"},[_c('label',[_vm._v("Nome")]),_c('v-text-field',{ref:"name",attrs:{"type":"name","filled":"","rounded":"","dense":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"button"},[_c('SubmitFormButton',{attrs:{"title":'Buscar',"disabled":_vm.submitedForm},on:{"submitForm":function($event){return _vm.submitForm()}}})],1)]),(_vm.displayTable)?_c('div',{staticClass:"box-table"},[_c('v-data-table',{staticClass:"data-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.managers,"page":_vm.page,"sort-by":"name","items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){return [_c('v-avatar',{attrs:{"color":"#dddddd","size":"40"}})]}},{key:"item.managers",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","title":"Visualizar"},on:{"click":function($event){return _vm.viewManagers(item)}}},[_vm._v(" "+_vm._s(item.managers)+" ")])]}},{key:"item.allowed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getColorComputed(item.allowed),"dark":"","outlined":""}},[_vm._v(" "+_vm._s(item.allowed ? 'ativo' : 'inativo')+" ")])]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconGenderComputed(item.gender).color}},[_vm._v(" "+_vm._s(_vm.getIconGenderComputed(item.gender).icon)+" ")])]}},{key:"item.activated",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconActivatedComputed(item.activated).color}},[_vm._v(" "+_vm._s(_vm.getIconActivatedComputed(item.activated).icon)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn-vinculate",attrs:{"color":"primary","small":"","rounded":"","disabled":item.linked},on:{"click":function($event){return _vm.sendInviteToManager(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-plus ")]),_vm._v(" Solicitar vínculo ")],1)]}}],null,true)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }