<template>
    <div class="sector">
        <SanackBar
            :snackActivated="snackActivated"
            :snackMessage="snackMessage"
            @closeSnackBar="closeSnack()" />
        <div class="sector-head">
            <h1 class="sector-head-title">{{ titles.sector }}</h1>
        </div>
        <div class="search">
            <div class="field">
                <label>Nome</label>
                <v-text-field v-model="name" ref="name" type="name" filled rounded dense>
                </v-text-field>
            </div>
            <div class="button">
                <SubmitFormButton
                    :title="'Buscar'"
                    :disabled="submitedForm"
                    @submitForm="submitForm()" />
            </div>
        </div>
        <div class="box-table" v-if="displayTable">
            <v-data-table
                :headers="headers"
                :items="managers"
                :page.sync="page"
                sort-by="name"
                :items-per-page="itemsPerPage"
                hide-default-footer
                class="data-table elevation-2"
                @page-count="pageCount = $event">
                <template v-slot:[`item.avatar`]="{}">
                    <v-avatar color="#dddddd" size="40"></v-avatar>
                </template>
                <template v-slot:[`item.managers`]="{ item }">
                    <v-btn @click="viewManagers(item)" icon title="Visualizar">
                        {{ item.managers }}
                    </v-btn>
                </template>
                <template v-slot:[`item.allowed`]="{ item }">
                    <v-chip small :color="getColorComputed(item.allowed)" dark outlined>
                        {{ item.allowed ? 'ativo' : 'inativo' }}
                    </v-chip>
                </template>
                <template v-slot:[`item.gender`]="{ item }">
                    <v-icon :color="getIconGenderComputed(item.gender).color">
                        {{ getIconGenderComputed(item.gender).icon }}
                    </v-icon>
                </template>
                <template v-slot:[`item.activated`]="{ item }">
                    <v-icon :color="getIconActivatedComputed(item.activated).color">
                        {{ getIconActivatedComputed(item.activated).icon }}
                    </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                        color="primary"
                        class="btn-vinculate"
                        small
                        rounded
                        :disabled="item.linked"
                        @click="sendInviteToManager(item.id)">
                        <v-icon left> mdi-account-plus </v-icon>
                        Solicitar vínculo
                    </v-btn>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import SanackBar from '@/components/snack/SanackBar.vue';
    import SubmitFormButton from '@/components/SubmitFormButton.vue';

    export default {
        name: 'FindManager',
        components: { SanackBar, SubmitFormButton },
        data() {
            return {
                snackActivated: false,
                snackMessage: null,
                submitedForm: false,
                displayTable: false,
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                name: null,
                managers: [],
                idsManagersQueued: [],
                idsManagersLinked: [],
                titles: {
                    sector: 'Buscar profissionais'
                },
                headers: [
                    {
                        text: 'Foto',
                        align: 'center',
                        sortable: false,
                        value: 'avatar'
                    },
                    { text: 'Nome', value: 'name' },
                    { text: 'Email', value: 'email' },
                    { text: 'Ações', value: 'actions', sortable: false, align: 'center' }
                ]
            };
        },
        computed: {
            getColorComputed() {
                return (allowed) => (allowed ? 'green' : 'red');
            },
            getIconGenderComputed() {
                return (gender) => {
                    return {
                        icon: gender === 'f' ? 'mdi-face-woman' : 'face',
                        color: gender === 'f' ? 'pink' : 'blue'
                    };
                };
            },
            getIconActivatedComputed() {
                return (activated) => {
                    return {
                        icon: 'mdi-email-check',
                        color: activated ? 'green' : '#d0d0d0'
                    };
                };
            }
        },
        methods: {
            activeSnack(message) {
                this.snackActivated = true;
                this.snackMessage = message;
            },
            closeSnack() {
                this.snackActivated = false;
            },
            async serviceCreateManagerQueue(managerId) {
                await this.$service.http['company']
                    .createUserQueue(managerId)
                    .then((_) => null)
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack('Ocorreu um erro!');
                    });
            },
            async sendInviteToManager(managerId) {
                this.$store.commit('setGlobalLoading', true);

                const managerIndex = this.managers.findIndex((e) => e.id === managerId);

                if (managerIndex !== -1) {
                    await this.serviceCreateManagerQueue(managerId);

                    this.managers[managerIndex].linked = true;
                }

                this.$store.commit('setGlobalLoading', false);
            },
            mergeStatusManagersInData() {
                this.managers.forEach((e) => {
                    const status =
                        this.idsManagersLinked.includes(e.id) ||
                        this.idsManagersQueued.includes(e.id);

                    this.$set(e, 'linked', status);
                });
            },
            async setListIdsManagersQueuedInData() {
                await this.$service.http['company']
                    .getListUsersQueued('MANAGER')
                    .then((result) => {
                        const managersQueued = result.data.docs;

                        if (managersQueued.length) {
                            this.idsManagersQueued = managersQueued.map((e) => e.userId);
                        }
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack('Ocorreu um erro!');
                    });
            },
            async setListIdsManagersLinkedInData() {
                await this.$service.http['company']
                    .getListUsersLinkedByRole('MANAGER')
                    .then((result) => {
                        const managersLinked = result.data.docs;

                        if (managersLinked.length) {
                            this.idsManagersLinked = managersLinked.map((e) => e.userId);
                        }
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack('Ocorreu um erro!');
                    });
            },
            async setManagersFoundInData() {
                await this.$service.http['company']
                    .getUsersByNameContainAndRole(this.name, 'MANAGER')
                    .then((result) => {
                        const managers = result.data.docs;

                        if (managers.length) {
                            this.managers = managers;
                        }
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack('Ocorreu um erro!');
                    });
            },
            async executeService() {
                await this.setManagersFoundInData();

                if (this.managers.length) {
                    await this.setListIdsManagersLinkedInData();

                    await this.setListIdsManagersQueuedInData();

                    this.mergeStatusManagersInData();

                    this.displayTable = true;
                }
            },
            validateSubmitForm() {
                if (!this.name) {
                    this.activeSnack('Ocorreu um erro!');
                    return false;
                }

                return true;
            },
            async submitForm() {
                if (!this.validateSubmitForm()) return;

                this.submitedForm = true;

                this.$store.commit('setGlobalLoading', true);

                await this.executeService();

                this.submitedForm = false;

                this.$store.commit('setGlobalLoading', false);
            }
        }
    };
</script>

<style scoped>
    .search {
        display: flex;
    }

    .search .field {
        margin-right: 10px;
    }

    .search .button {
        margin-top: 24px;
    }

    .confirm {
        padding: 5px;
    }

    .btn-vinculate {
        text-transform: none !important;
        font-weight: bold;
    }

    .sector-head {
        margin-bottom: 15px;
    }

    .sector-head-title {
        text-align: left !important;
    }

    .v-data-table-header {
        background-color: #d0d0d0;
    }
</style>
